import { MrbBaseViewStore } from "mrb/core";
import { NakiMobileFeaturedSectionCoverImageUploadViewStore } from "administration/naki-mobile/stores";

class FeaturedSectionItemViewStore extends MrbBaseViewStore {
    constructor(rootStore, { item, routeStore }) {
        super(rootStore);
        this.item = item;
        this.routeStore = routeStore;

        this.coverImageUploadViewStore = new NakiMobileFeaturedSectionCoverImageUploadViewStore(this.rootStore, {
            routeStore: this.routeStore,
        });

        this.reaction(
            () => this.coverImageUploadViewStore.uploadedFileId,
            (fileId) => {
                this.item.fileEntryId = fileId;
            }
        );

        this.uploadImage = this.uploadImage.bind(this);
        this.revertToDefaultArtwork = this.revertToDefaultArtwork.bind(this);
    }

    async onInit() {
        if (this.item.fileEntryId) {
            this.coverImageUploadViewStore.setImageUrl(
                this.routeStore.getFeaturedSectionCoverImageUrl(this.item.fileEntryId)
            );
        }
    }

    uploadImage(event) {
        this.coverImageUploadViewStore.uploadImage(event);
    }

    revertToDefaultArtwork() {
        this.coverImageUploadViewStore.setImageUrl(null);
        this.coverImageUploadViewStore.changeUploadedFileId(null);
        this.coverImageUploadViewStore.fileUploadStore.clear();
        this.item.fileEntryId = null;
    }
}

export default FeaturedSectionItemViewStore;
