import { MrbBaseEditViewStore } from "mrb/core";
import { observable, runInAction, makeObservable } from "mobx";
import { MrbFieldSelectStore } from "mrb/components/select";
import { StationEditForm } from "administration/station/forms";
import { StationCoverImageUploadViewStore, StationPromoEditViewStore } from "administration/station/stores";
import { map, isEmpty } from "lodash";

class StationEditViewStore extends MrbBaseEditViewStore {
    @observable overrideRadioUsersSettingsIsVisible = false;
    @observable overrideAppUsersSettingsIsVisible = false;

    constructor(rootStore, { routeStore }) {
        const id = rootStore.routerStore.routerState.params.id;
        super(rootStore, {
            id: id,
            name: "station",
            FormClass: StationEditForm,
            autoFocusField: "title",
            navigateBack: "master.administration.station.list",
            successCreateNotification: null,
            successUpdateNotification: null,
            redirectOnCreateSuccess: ({ id }) =>
                this.rootStore.routerStore.goTo("master.administration.station.preview", { id: id }),
            redirectOnUpdateSuccess: () =>
                this.rootStore.routerStore.goTo("master.administration.station.preview", { id: this.id }),
        });

        makeObservable(this);
        this.routeStore = routeStore;
        this.stationPromoEditViewStore = new StationPromoEditViewStore(rootStore, {
            stationEditViewStore: this,
            form: this.form,
        });
        this.coverImageUploadViewStore = new StationCoverImageUploadViewStore(rootStore, this.routeStore);

        this.locationSelectStore = new MrbFieldSelectStore(
            {
                isSearchable: true,
                virtualized: false,
                actions: {
                    find: (searchTerm) => {
                        return this.routeStore.findLocations(searchTerm);
                    },
                    createOption: (name) => {
                        return this.routeStore.createLocation({ name });
                    },
                },
            },
            this.form.$("locationId")
        );

        this.languageSelectStore = new MrbFieldSelectStore(
            {
                isSearchable: true,
                virtualized: false,
                actions: {
                    find: (searchTerm) => {
                        return this.routeStore.findLanguages(searchTerm);
                    },
                    createOption: (name) => {
                        return this.routeStore.createLanguage({ name });
                    },
                },
            },
            this.form.$("languageId")
        );

        this.genreSelectStore = new MrbFieldSelectStore(
            {
                isSearchable: true,
                virtualized: false,
                isMulti: true,
                actions: {
                    find: (searchTerm) => {
                        return this.routeStore.findGenres(searchTerm);
                    },
                    createOption: (name) => {
                        return this.routeStore.createGenre({ name });
                    },
                },
            },
            this.form.$("genreIds")
        );

        if (this.isEdit) {
            this.reaction(
                () => this.form.$("isTurnedOnByDefault").value,
                (isTurnedOnByDefault) => {
                    if (isTurnedOnByDefault !== this.item.isTurnedOnByDefault) {
                        runInAction(() => {
                            this.overrideRadioUsersSettingsIsVisible = true;
                        });
                    } else {
                        runInAction(() => {
                            this.overrideRadioUsersSettingsIsVisible = false;
                            this.form.$("overrideRadioUsersSettings").value = false;
                        });
                    }
                }
            );
            this.reaction(
                () => this.form.$("isTurnedOnByDefaultInApp").value,
                (isTurnedOnByDefault) => {
                    if (isTurnedOnByDefault !== this.item.isTurnedOnByDefaultInApp) {
                        runInAction(() => {
                            this.overrideAppUsersSettingsIsVisible = true;
                        });
                    } else {
                        runInAction(() => {
                            this.overrideAppUsersSettingsIsVisible = false;
                            this.form.$("overrideAppUsersSettings").value = false;
                        });
                    }
                }
            );
        }

        this.reaction(
            () => this.form.$("isPremium").value,
            (isPremium) => {
                if (!isPremium) {
                    this.form.$("premiumInfo").set(null);
                }
            }
        );
    }

    get(id) {
        return this.routeStore.getStation(id);
    }

    setItem(item) {
        super.setItem(item);
        this.locationSelectStore.setSelectedItem(item.location);
        this.languageSelectStore.setSelectedItem(item.language);
        this.genreSelectStore.setSelectedItem(item.genres);
    }

    updateForm(values) {
        this.form.set({
            ...values,
            genreIds: values.genres ? map(values.genres, (genre) => genre.id) : null,
        });
    }

    resetForm() {
        this.form.set({
            isActive: true,
            isVisible: true,
            isVisibleInApp: true,
        });
    }

    async onInit() {
        await this.initializeResource();
        if (this.isEdit) {
            if (this.item.coverImageId) {
                this.coverImageUploadViewStore.setImageUrl(
                    this.routeStore.getStationCoverImageUrl(this.item.coverImageId)
                );
            }
            if (this.item.stationPromo && this.item.stationPromo.promoImageFileEntryId) {
                this.stationPromoEditViewStore.promoImageUploadViewStore.setImageUrl(
                    this.routeStore.getStationCoverImageUrl(this.item.stationPromo.promoImageFileEntryId)
                );
            }
        }
    }

    create(data) {
        data.coverImageId = this.coverImageUploadViewStore.uploadedFileId;
        this.updatePremiumInfo(data);
        this.updateStationPromo(data);
        return this.routeStore.createStation(data);
    }

    updatePremiumInfo(data) {
        if (!data.isPremium && data.premiumInfo.length > 0) {
            data.premiumInfo = null;
        }
    }

    updateStationPromo(data) {
        if (
            isEmpty(data.stationPromo.description) &&
            isEmpty(this.stationPromoEditViewStore.promoImageUploadViewStore.uploadedFileId)
        ) {
            delete data.stationPromo;
        } else {
            data.stationPromo.promoImageFileEntryId =
                this.stationPromoEditViewStore.promoImageUploadViewStore.uploadedFileId;
        }
    }

    async update(data) {
        if (this.coverImageUploadViewStore.hasChanged) {
            data.coverImageId = this.coverImageUploadViewStore.uploadedFileId;
        } else {
            data.coverImageId = this.item.coverImageId;
        }
        this.updatePremiumInfo(data);
        this.updateStationPromo(data);
        await this.routeStore.updateStation(data);
    }

    onCreateError(err) {
        this.rootStore.notificationStore.error("An unexpected error occurred while trying to create station.", err);
    }

    onUpdateError(err) {
        this.rootStore.notificationStore.error("An unexpected error occurred while trying to update station.", err);
    }

    onDestroy() {
        this.coverImageUploadViewStore.destroy();
        this.stationPromoEditViewStore.destroy();
    }
}

export default StationEditViewStore;
