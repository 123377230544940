import { action, observable, makeObservable } from "mobx";
import { MrbBaseViewStore } from "mrb/core";
import { MrbModalParams } from "mrb/common/models";
import {
    canExpandLeft,
    canExpandRight,
    canExpandTop,
    canExpandBottom,
    canCollapseTop,
    canCollapseLeft,
    canCollapseRight,
    canCollapseBottom,
} from "administration/naki-mobile/utils";
import { findIndex, filter } from "lodash";

class NakiMobileFeaturedSectionViewStore extends MrbBaseViewStore {
    rowsLimit = 3;
    columnsLimit = 3;

    @observable items = [];

    @observable isSubmitting = false;

    constructor(rootStore, { routeStore }) {
        super(rootStore);
        makeObservable(this);
        this.routeStore = routeStore;

        this.editItemModalParams = new MrbModalParams(
            {},
            {
                routeStore: this.routeStore,
            }
        );

        this.editItem = this.editItem.bind(this);
    }

    onInit() {
        return this.getFeaturedGrid();
    }

    @action.bound
    async getFeaturedGrid() {
        try {
            const grid = await this.routeStore.getFeaturedGrid();
            const mockGrid = [];
            for (let row = 1; row <= this.rowsLimit; row++) {
                for (let column = 1; column <= this.columnsLimit; column++) {
                    mockGrid.push({
                        stationId: null,
                        podcastId: null,
                        nakiNigunAlbumId: null,
                        NakiNigunArtistId: null,
                        gridRow: row,
                        gridColumn: column,
                        gridRowSpan: 1,
                        gridColumnSpan: 1,
                    });
                }
            }
            //this.setGrid(mockGrid);

            this.setGrid(grid);
        } catch (err) {
            this.rootStore.notificationStore.error(
                "An unexpected error occurred while trying to load featured section data. Please contact the support team."
            );
        }
    }

    @action.bound
    setIsSubmitting(value) {
        this.isSubmitting = value;
    }

    @action.bound
    async updateFeaturedGrid() {
        try {
            this.setIsSubmitting(true);
            await this.routeStore.updateFeaturedGrid(this.items);
        } catch (err) {
            this.rootStore.notificationStore.error(
                "An unexpected error occurred while trying to update featured section. Please contact the support team."
            );
        } finally {
            this.setIsSubmitting(false);
        }
    }

    @action.bound
    setGrid(items) {
        this.items = items;
    }

    gridConflict(rowNum, columnNum) {
        const index = findIndex(
            this.items,
            (item) =>
                rowNum >= item.gridRow &&
                rowNum <= item.gridRow + (item.gridRowSpan - 1) &&
                columnNum >= item.gridColumn &&
                columnNum <= item.gridColumn + (item.gridColumnSpan - 1)
        );
        return index !== -1;
    }

    editItem(item) {
        this.editItemModalParams.open({
            item: item,
        });
    }

    @action.bound
    removeGridElementsInConflict(newGridElement) {
        const itemsWithoutConflict = filter(
            this.items,
            (item) =>
                !(
                    newGridElement.gridColumn <= item.gridColumn + (item.gridColumnSpan - 1) &&
                    newGridElement.gridColumn + (newGridElement.gridColumnSpan - 1) >= item.gridColumn &&
                    newGridElement.gridRow <= item.gridRow + (item.gridRowSpan - 1) &&
                    newGridElement.gridRow + (newGridElement.gridRowSpan - 1) >= item.gridRow
                )
        );

        this.items = itemsWithoutConflict;
    }

    @action.bound
    ensureGridStructure() {
        for (let row = 0; row < this.rowsLimit; row++) {
            for (let column = 0; column < this.columnsLimit; column++) {
                const index = findIndex(
                    this.items,
                    (item) =>
                        row >= item.gridRow &&
                        row <= item.gridRow + (item.gridRowSpan - 1) &&
                        column >= item.gridColumn &&
                        column <= item.gridColumn + (item.gridColumnSpan - 1)
                );
                if (index === -1) {
                    // Item exists
                    this.items.push({
                        gridRow: row,
                        gridColumn: column,
                        gridRowSpan: 1,
                        gridColumnSpan: 1,
                    });
                }
            }
        }
    }

    @action.bound
    expandToLeft(item) {
        if (!canExpandLeft(item.gridColumn)) {
            // Expand not supported
            return;
        }

        const newGridElement = { ...item };

        // Push item to left and increase column span
        --newGridElement.gridColumn;
        newGridElement.gridColumnSpan++;

        //TODO: Find all items that should be moved
        this.removeGridElementsInConflict(newGridElement);
        this.items.push(newGridElement);
        this.ensureGridStructure();
    }

    @action.bound
    collapseLeft(item) {
        if (!canCollapseLeft(item.gridColumnSpan)) {
            // Collapse not supported
            return;
        }

        const newGridElement = { ...item };
        // Increase column span
        newGridElement.gridColumnSpan--;
        newGridElement.gridColumn++;

        //TODO: Find all items that should be moved
        this.removeGridElementsInConflict(newGridElement);
        this.items.push(newGridElement);
        this.ensureGridStructure();
    }

    @action.bound
    expandToRight(item) {
        if (!canExpandRight(item.gridColumn, item.gridColumnSpan, this.columnsLimit)) {
            // Expand not supported
            return;
        }

        const newGridElement = { ...item };

        // Increase column span
        newGridElement.gridColumnSpan++;

        this.removeGridElementsInConflict(newGridElement);
        this.items.push(newGridElement);
        this.ensureGridStructure();
    }

    @action.bound
    collapseRight(item) {
        if (!canCollapseRight(item.gridColumnSpan)) {
            // Collapse not supported
            return;
        }

        const newGridElement = { ...item };
        // Increase column span
        newGridElement.gridColumnSpan--;

        //TODO: Find all items that should be moved
        this.removeGridElementsInConflict(newGridElement);
        this.items.push(newGridElement);
        this.ensureGridStructure();
    }

    @action.bound
    expandToTop(item) {
        if (!canExpandTop(item.gridRow)) {
            // Expand not supported
            return;
        }

        const newGridElement = { ...item };

        // Push item to top and increase row span
        --newGridElement.gridRow;
        newGridElement.gridRowSpan++;

        this.removeGridElementsInConflict(newGridElement);
        this.items.push(newGridElement);
        this.ensureGridStructure();
    }

    @action.bound
    collapseTop(item) {
        if (!canCollapseTop(item.gridRowSpan)) {
            // Collapse not supported
            return;
        }

        const newGridElement = { ...item };
        // Increase column span
        newGridElement.gridRow++;
        newGridElement.gridRowSpan--;

        //TODO: Find all items that should be moved
        this.removeGridElementsInConflict(newGridElement);
        this.items.push(newGridElement);
        this.ensureGridStructure();
    }

    @action.bound
    expandToBottom(item) {
        if (!canExpandBottom(item.gridRow, item.gridRowSpan, this.rowsLimit)) {
            // Expand not supported
            return;
        }

        const newGridElement = { ...item };

        // Increase row span
        newGridElement.gridRowSpan++;

        this.removeGridElementsInConflict(newGridElement);
        this.items.push(newGridElement);
        this.ensureGridStructure();
    }

    @action.bound
    collapseBottom(item) {
        if (!canCollapseBottom(item.gridRowSpan)) {
            // Collapse not supported
            return;
        }

        const newGridElement = { ...item };
        // Increase column span
        newGridElement.gridRowSpan--;

        //TODO: Find all items that should be moved
        this.removeGridElementsInConflict(newGridElement);
        this.items.push(newGridElement);
        this.ensureGridStructure();
    }

    @action.bound
    clearSelection(item) {
        item.stationId = null;
        item.station = null;
        item.podcastId = null;
        item.podcast = null;
        item.nakiNigunArtistId = null;
        item.nakiNigunArtist = null;
        item.nakiNigunAlbumId = null;
        item.nakiNigunAlbum = null;
    }
}

export default NakiMobileFeaturedSectionViewStore;
